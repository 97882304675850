import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import {
  catchError,
  filter,
  iif,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import {
  INITIAL_MOVE_STATE,
  Meter,
  MetersByEnergy,
  MoveDTO,
  MoveMeter,
  MoveRegister,
  MoveSite,
  MoveState,
} from '@app/core/state/move.state';
import dayjs from 'dayjs';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { SharedModule } from '@app/shared/shared.module';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import { TranslateModule } from '@ngx-translate/core';
import { EanControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/ean-control/ean-control.component';
import { MoveDeliveryPointService } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/move-delivery-point.service';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { DeliveryPoint } from '@app/modules/customer-zone/move/models/movein.interface';

@Component({
  selector: 'app-my-meters',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    DatePickerFieldModule,
    NavigationComponent,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    TranslateModule,
    EanControlComponent,
  ],
  templateUrl: './my-meters.component.html',
  styleUrls: ['./my-meters.component.scss'],
})
export class MyMetersComponent extends MoveFormStep<MoveDTO> implements OnInit, OnDestroy {
  readonly AlertType = AlertType;
  readonly EnergyType = EnergyType;
  private moveDTOSubscription: Subscription;
  form: FormGroup;
  meters$: Observable<ApiResponse<MetersByEnergy>>;
  notifier: Subject<void> = new Subject<void>();
  loadingState: Map<string, boolean> = new Map<string, boolean>();
  submittedForm: boolean = false;
  moveDTO: MoveDTO;

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly formBuilder: FormBuilder,
    private readonly deliveryPointService: MoveDeliveryPointService
  ) {
    super(moveFormFacade);
  }

  getMetersFormValue(ean: string): FormArray {
    return this.form.get(`${ean}.meters`) as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {},
      {
        validators: (formGroup: FormGroup) => {
          const hasSelectedMeter = Object.values(formGroup.value).some((value) => value['selected']);
          return hasSelectedMeter ? null : { noMeterSelected: true };
        },
      }
    );

    this.moveDTOSubscription = this.moveFormFacade.moveDTO$.subscribe((moveDTO: MoveDTO) => {
      this.moveDTO = moveDTO;
    });

    this.meters$ = this.moveFormFacade.moveDTO$.pipe(
      filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
      take(1),
      switchMap((moveDTO: MoveDTO) =>
        this.getMetersByEnergy().pipe(
          take(1),
          catchError(() => {
            return of({ error: true });
          }),
          tap((response: ApiResponse<MetersByEnergy>): void => {
            if (response?.data) {
              const metersByEnergy: MetersByEnergy = response?.data;
              this.initialiseFormWithMeters(metersByEnergy);
              const deliveryPoints: DeliveryPoint[] = moveDTO?.sites?.[0]?.deliveryPoints;
              if (deliveryPoints?.length) {
                deliveryPoints.forEach((dp: DeliveryPoint): void => {
                  this.addDeliveryPointAndCreateMeterIndexes(dp, dp?.meters);
                });
              }
              this.moveFormFacade.updateData({ moveDTO });
            }
          }),
          map(
            (response: ApiResponse<MetersByEnergy>): ApiResponse<MetersByEnergy> => ({
              loading: false,
              data: response?.data,
            })
          ),
          startWith({ loading: true })
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
    if (this.moveDTOSubscription) {
      this.moveDTOSubscription.unsubscribe();
    }
  }

  onNextClickedDefault(): void {
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.next();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
    } else {
      this.submittedForm = true;
      this.form.markAllAsTouched();
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  // TODO Refactor all of this
  onCheckboxChange(event: Event, meter: Meter): void {
    const { checked }: { checked: boolean } = <HTMLInputElement>event.target;
    if (checked) {
      this.loadingState.set(meter?.ean, true);
      const deliveryPoint: DeliveryPoint = this.deliveryPointService.createDeliveryPoint(meter);
      this.deliveryPointService.addDeliveryPoint(meter?.ean, deliveryPoint);
    } else {
      this.form.get(meter?.ean).get('selected').setValue(false);
      this.deliveryPointService.removeDeliveryPoint(meter?.ean);
    }

    // Update moveDTO value state
    const deliveryPoints: DeliveryPoint[] = this.deliveryPointService.getListOfDeliveryPoints();
    const moveDTO: MoveDTO = this.updatedMoveDeliveryPoint(deliveryPoints);
    this.moveFormFacade.updateData({ moveDTO });

    // PUT new moveDTO & call preswitch (add if this.deliveryPointMap.size > 0)
    this.moveFormFacade
      .update(moveDTO)
      .pipe(
        take(1),
        this.moveFormFacade.getCatchErrorOperatorFunction(),
        switchMap(
          (): Observable<PreSwitchLight[]> => iif(() => checked, this.moveFormFacade.getPreSwitchLight(), of(null))
        )
      )
      .pipe(this.moveFormFacade.getCatchErrorOperatorFunction())
      .subscribe({
        next: (response: PreSwitchLight[]): void => {
          if (response && response.length > 0) {
            const data: PreSwitchLight = response.find((entry: PreSwitchLight): boolean => entry.ean === meter?.ean);
            const dp: DeliveryPoint = this.deliveryPointService.get(meter?.ean);
            this.addDeliveryPointAndCreateMeterIndexes(dp, data?.meters);
            this.moveFormFacade.updateData({ preSwitchLight: response });
            this.moveFormFacade.update(moveDTO);
          } else if (checked) {
            this.loadingState.set(meter?.ean, false);
            this.moveFormFacade.getCatchErrorOperatorFunction();
          }
          this.loadingState.set(meter?.ean, false);
        },
        error: (e) => {
          this.loadingState.set(meter?.ean, false);
        },
      });
  }

  isLoadingData(): boolean {
    return Array.from(this.loadingState.values()).some((value: boolean) => value);
  }

  saveFormData(): Observable<MoveDTO> {
    const state: MoveState = this.moveFormFacade.state$.value;
    const deliveryPoints: DeliveryPoint[] = state?.moveDTO?.sites?.[0]?.deliveryPoints;
    const delPointsWithFilledValues = deliveryPoints.map((dp: DeliveryPoint) => {
      return {
        ...dp,
        meters: (dp.meters = dp.meters.map((meter: MoveMeter, meterIndex: number) => {
          meter.registers = meter.registers.map((register: MoveRegister, index: number) => {
            register.value = this.getIndexFormValue(dp.code, meterIndex, index);
            return register;
          });
          return meter;
        })),
      };
    });

    return this.updateMoveData(delPointsWithFilledValues);
  }

  private updateMoveData(deliveryPoints: DeliveryPoint[]): Observable<MoveDTO> {
    const moveDTO: MoveDTO = this.updatedMoveDeliveryPoint(deliveryPoints);
    this.moveFormFacade.updateData({ moveDTO });
    return this.moveFormFacade.update(moveDTO).pipe(take(1));
  }

  private getIndexFormValue(ean: string, meterIndex: number, index: number): string {
    const metersFormArray: FormArray = <FormArray>this.form.get(`${ean}.meters`);
    const indexesFormArray: FormArray = <FormArray>metersFormArray?.at(meterIndex)?.get('indexes');
    const indexFormGroup: FormGroup = <FormGroup>indexesFormArray?.at(index);
    const unitValue: string = indexFormGroup?.get('unit').value;
    const decimalValue: string = indexFormGroup?.get('decimal').value;
    return !unitValue && !decimalValue ? null : `${unitValue || 0}.${decimalValue || 0}`;
  }

  private addDeliveryPointAndCreateMeterIndexes(dp: DeliveryPoint, meters: MoveMeter[]): void {
    if (meters?.length) {
      const deliveryPoint: DeliveryPoint = this.deliveryPointService.enrichDeliveryPointWithExtraInfo(dp, meters);
      this.deliveryPointService.addDeliveryPoint(dp?.code, deliveryPoint);
      this.createMeterIndexes(dp, meters);
      this.form.get(dp?.code)?.get('selected').setValue(true);
    }
  }

  private createMeterIndexes(dp: DeliveryPoint, meters: MoveMeter[]): void {
    if (this.hasNoMeterFields(dp?.code)) {
      const metersFields: FormGroup[] = meters.map((meter: MoveMeter): FormGroup => this.createMeterFormGroup(meter));
      metersFields.forEach((meter: FormGroup) => this.getMetersFormValue(dp?.code).push(meter));
    }
  }

  private hasNoMeterFields(ean: string): boolean {
    return this.getMetersFormValue(ean)?.length === 0;
  }

  private getMetersByEnergy(): Observable<ApiResponse<MetersByEnergy>> {
    return this.moveFormFacade.getAvailableMeters().pipe(
      catchError((error) => of({ error: true })),
      map((response: ApiResponse<Meter[]>): ApiResponse<MetersByEnergy> => {
        return {
          data: {
            electricity: response?.data
              .filter((meter: Meter): boolean => meter.energy === EnergyType.ELECTRICITY)
              .filter(
                (item: Meter, index: number, self: Meter[]): boolean =>
                  index === self.findIndex((m: Meter): boolean => m?.ean === item.ean)
              ),
            gas: response?.data
              .filter((meter: Meter): boolean => meter.energy === EnergyType.GAS)
              .filter(
                (item: Meter, index: number, self: Meter[]): boolean =>
                  index === self.findIndex((m: Meter): boolean => m?.ean === item.ean)
              ),
          },
          loading: false,
        };
      })
    );
  }

  private initialiseFormWithMeters(metersByEnergy: MetersByEnergy): void {
    const meters: Meter[] = metersByEnergy.electricity.concat(metersByEnergy.gas);
    meters.forEach((meter: Meter) =>
      this.form.addControl(
        meter.ean,
        this.formBuilder.group({
          selected: this.formBuilder.control(false, Validators.required),
          meters: this.formBuilder.array([]),
        })
      )
    );
  }

  private createMeterFormGroup(meter: MoveMeter): FormGroup {
    let isMovingDateInFuture = dayjs(this.moveDTO.ilcDate).startOf('day').isAfter(dayjs().startOf('day'));
    let hasNoDRE =
      this.moveDTO.sites[0].deliveryPoints[0].dreDocumentStatus ===
      UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE;
    return this.formBuilder.group({
      meterNumber: meter.number,
      indexes: this.formBuilder.array(
        meter.registers.map((register: MoveRegister) => {
          return this.formBuilder.group({
            timeFrame: [register?.timeFrame],
            type: [register?.direction],
            unit: [
              register?.value?.split('.')[0],
              [
                Validators.maxLength(6),
                hasNoDRE && !isMovingDateInFuture ? Validators.required : Validators.nullValidator,
              ],
            ],
            decimal: [
              register?.value?.split('.')[1],
              [
                Validators.minLength(1),
                Validators.maxLength(1),
                hasNoDRE && !isMovingDateInFuture ? Validators.required : Validators.nullValidator,
              ],
            ],
            value: [register?.value],
          });
        })
      ),
    });
  }

  private updatedMoveDeliveryPoint(deliveryPoints: DeliveryPoint[]): MoveDTO {
    const moveDTO: MoveDTO = this.moveFormFacade.state$.value.moveDTO;
    const [site]: MoveSite[] = moveDTO.sites;
    site.deliveryPoints = site?.deliveryPoints?.length
      ? this.addIdGeneratedByBackend(site, deliveryPoints)
      : deliveryPoints;
    return moveDTO;
  }

  private addIdGeneratedByBackend(site: MoveSite, deliveryPoints: DeliveryPoint[]): DeliveryPoint[] {
    return deliveryPoints.map(
      (dp: DeliveryPoint): DeliveryPoint => ({
        id: site?.deliveryPoints?.find(({ code }): boolean => code === dp.code)?.id,
        ...dp,
      })
    );
  }

  protected readonly Array = Array;
}

_('pages.move.myMeters.noInjectionWarning');
_('pages.move.myMeters.meterInput.HIGH.CONSUMPTION');
_('pages.move.myMeters.meterInput.HIGH.INJECTION');
_('pages.move.myMeters.meterInput.HIGH.INJECTION_READING');
_('pages.move.myMeters.meterInput.HIGH.PRODUCTION');
_('pages.move.myMeters.meterInput.HIGH.READING');
_('pages.move.myMeters.meterInput.LOW.CONSUMPTION');
_('pages.move.myMeters.meterInput.LOW.INJECTION');
_('pages.move.myMeters.meterInput.LOW.INJECTION_READING');
_('pages.move.myMeters.meterInput.LOW.PRODUCTION');
_('pages.move.myMeters.meterInput.LOW.READING');
_('pages.move.myMeters.meterInput.MONO.CONSUMPTION');
_('pages.move.myMeters.meterInput.MONO.INJECTION');
_('pages.move.myMeters.meterInput.MONO.INJECTION_READING');
_('pages.move.myMeters.meterInput.MONO.PRODUCTION');
_('pages.move.myMeters.meterInput.MONO.READING');
_('pages.move.myMeters.meterInput.NIGHT_EXCLUSIVE.CONSUMPTION');
_('pages.move.myMeters.meterInput.NIGHT_EXCLUSIVE.INJECTION');
_('pages.move.myMeters.meterInput.NIGHT_EXCLUSIVE.INJECTION_READING');
_('pages.move.myMeters.meterInput.NIGHT_EXCLUSIVE.PRODUCTION');
_('pages.move.myMeters.meterInput.NIGHT_EXCLUSIVE.READING');
_('pages.move.myMeters.meterInput.TOTAL_HOUR.CONSUMPTION');
_('pages.move.myMeters.meterInput.TOTAL_HOUR.INJECTION');
_('pages.move.myMeters.meterInput.TOTAL_HOUR.INJECTION_READING');
_('pages.move.myMeters.meterInput.TOTAL_HOUR.PRODUCTION');
_('pages.move.myMeters.meterInput.TOTAL_HOUR.READING');
_('pages.move.myMeters.unit.ELECTRICITY');
_('pages.move.myMeters.unit.GAS');
