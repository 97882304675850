import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { InvoicingMethod } from '@app/core/state/move.state';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandableRadioBtnComponent } from '../expandable-radio-btn/expandable-radio-btn.component';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';

@Component({
  selector: 'app-invoicing-method',
  standalone: true,
  imports: [CommonModule, TranslateModule, ExpandableRadioBtnComponent, InputErrorComponent],
  templateUrl: './invoicing-method.component.html',
  styleUrls: ['./invoicing-method.component.scss'],
})
export class InvoicingMethodComponent {
  @Input() submittedForm: boolean;
  @Input() billingEmail: string;
  @Input() defaultInvoiceDeliveryChannel: InvoicingMethod;
  readonly InvoicingMethod = InvoicingMethod;

  constructor(private controlContainer: ControlContainer) {}

  get invoicingMethodControl(): FormControl<InvoicingMethod> {
    return (this.controlContainer?.control as FormGroup)?.controls?.invoicingMethod as FormControl<InvoicingMethod>;
  }

  onInvoicingMethodChange(value: InvoicingMethod): void {
    this.invoicingMethodControl.setValue(value);
  }
}
