<div [class.cursor-not-allowed]="readonly">
  <div class="card border-none md:box-shadow-small md:mb-8" [class.readonly]="readonly">
    <img ngSrc="./assets/img/box-move.svg" width="50" height="50" class="mb-8" alt="moving box" />
    <div class="content mb-8" *ngIf="site$ | async as site">
      <span class="text-sm text-grey-dark60/50 inline-block w-full"
        >{{ 'components.move.form.myCurrentAddress' | translate }}:</span
      >
      <span class="text-grey-dark/90 inline-block w-full">
        {{ site?.streetName }}, {{ site?.streetNumber }} {{ site?.boxNumber }} <br />
        {{ site?.zipCode }} {{ site?.town }}
      </span>
    </div>
    <div *ngIf="meters?.length" class="content mb-8">
      <app-move-meter-tag [meters]="meters"></app-move-meter-tag>
    </div>
    <div *ngIf="moveState?.moveDTO?.ilcDate?.length" class="content mb-8">
      <span class="text-sm text-grey-dark60/50 inline-block w-full"
        >{{ 'pages.move.finalize.summaryMovingInfo.desiredMoveDate' | translate }}:</span
      >
      <span class="text-grey-dark/90 inline-block w-full">
        {{ moveState?.moveDTO?.ilcDate | date: 'dd/MM/yyyy' }}
      </span>
    </div>
    <div *ngIf="moveState?.registration?.sites?.[0]?.address?.street?.length" class="content mb-8">
      <span class="text-sm text-grey-dark60/50 inline-block w-full">{{
        'pages.move.summaryAside.yourNewAddress' | translate
      }}</span>
      <span class="text-grey-dark/90 inline-block w-full">
        {{ moveState?.registration?.sites?.[0]?.address?.street }},
        {{ moveState?.registration?.sites?.[0]?.address?.streetNumber }}
        {{ moveState?.registration?.sites?.[0]?.address?.box }} <br />
        {{ moveState?.registration?.sites?.[0]?.address?.zip }} {{ moveState?.registration?.sites?.[0]?.address?.city }}
      </span>
    </div>
    <!-- TODO CTA is hidden, save for later function doesn't work now, we will tackle that during maintenance -->
    <!-- <div *ngIf="moveState?.moveDTO?.id && !readonly" class="text-center">
      <button (click)="saveData()" class="btn btn-secondary font-bold h-fit inline-block w-full">
        {{ 'components.move.form.button.saveForLater' | translate }}
      </button>
    </div> -->
  </div>

  <div class="w-full text-center cursor-pointer mb-8" *ngIf="moveState?.moveDTO?.id && !readonly" (click)="archive()">
    <span class="text-primary font-bold text-sm inline-block m-auto">
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="inline-block mr-2 align-[-2px]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.433594 4.00006C0.433594 3.6871 0.687299 3.43339 1.00026 3.43339L13.0003 3.43339C13.3132 3.43339 13.5669 3.6871 13.5669 4.00006C13.5669 4.31302 13.3132 4.56672 13.0003 4.56672L1.00026 4.56672C0.687299 4.56672 0.433594 4.31302 0.433594 4.00006Z"
          fill="#ED0000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.66693 1.90006C5.46359 1.90006 5.26859 1.98083 5.12481 2.12461C4.98103 2.26839 4.90026 2.46339 4.90026 2.66672V3.43339L9.10026 3.43339V2.66672C9.10026 2.46339 9.01949 2.26839 8.87571 2.12461C8.73193 1.98083 8.53693 1.90006 8.33359 1.90006L5.66693 1.90006ZM10.2336 3.43339V2.66672C10.2336 2.16281 10.0334 1.67954 9.6771 1.32322C9.32078 0.966902 8.83751 0.766724 8.33359 0.766724L5.66693 0.766724C5.16302 0.766724 4.67974 0.966902 4.32342 1.32322C3.96711 1.67954 3.76693 2.16281 3.76693 2.66672V3.43339H2.33359C2.02063 3.43339 1.76693 3.6871 1.76693 4.00006L1.76693 13.3334C1.76693 13.8373 1.96711 14.3206 2.32342 14.6769C2.67974 15.0332 3.16302 15.2334 3.66693 15.2334L10.3336 15.2334C10.8375 15.2334 11.3208 15.0332 11.6771 14.6769C12.0334 14.3206 12.2336 13.8373 12.2336 13.3334L12.2336 4.00006C12.2336 3.6871 11.9799 3.43339 11.6669 3.43339H10.2336ZM2.90026 4.56672L2.90026 13.3334C2.90026 13.5367 2.98103 13.7317 3.12481 13.8755C3.26859 14.0193 3.46359 14.1001 3.66693 14.1001L10.3336 14.1001C10.5369 14.1001 10.7319 14.0193 10.8757 13.8755C11.0195 13.7317 11.1003 13.5367 11.1003 13.3334L11.1003 4.56672L2.90026 4.56672Z"
          fill="#ED0000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.33359 6.76672C8.64655 6.76672 8.90026 7.02043 8.90026 7.33339L8.90026 11.3334C8.90026 11.6464 8.64655 11.9001 8.33359 11.9001C8.02063 11.9001 7.76693 11.6464 7.76693 11.3334V7.33339C7.76693 7.02043 8.02063 6.76672 8.33359 6.76672Z"
          fill="#ED0000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.66693 6.76672C5.97989 6.76672 6.23359 7.02043 6.23359 7.33339V11.3334C6.23359 11.6464 5.97989 11.9001 5.66693 11.9001C5.35397 11.9001 5.10026 11.6464 5.10026 11.3334V7.33339C5.10026 7.02043 5.35397 6.76672 5.66693 6.76672Z"
          fill="#ED0000"
        />
      </svg>
      {{ 'components.move.form.button.cancelMyMove' | translate }}
    </span>
  </div>
</div>
