import { Direction, MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import * as FormUtils from '@app/shared/utils/utils.form';
import { RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { FormGroup } from '@angular/forms';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
export enum MeterTypeOptions {
  MONO = 'pages.move.newMeters.smartMeterAndMeterType.MONO',
  BI = 'pages.move.newMeters.smartMeterAndMeterType.BI',
  SMART_MONO = 'pages.move.newMeters.smartMeterAndMeterType.SMART_MONO',
  SMART_BI = 'pages.move.newMeters.smartMeterAndMeterType.SMART_BI',
}

export interface IndexesFrontend {
  timeFrame?: RegisterType;
  type?: Direction;
  unit?: string | null;
  decimal?: string | null;
  value: string | null;
}

export interface ElectricityMeterFrontend extends GasMeterFrontend {
  smartMeterAndMeterType?: string | null;
  smartMeter?: boolean;
  exclusiveNight?: boolean;
  peakPower?: number | null;
  hasSolarPanels?: boolean;
  solarPanelsKva?: number | null;
  solarPanelsInstallationDateStarting2024?: boolean;
  injectionTariff?: boolean;
}

export interface GasMeterFrontend {
  noMeter?: boolean;
  ean?: string | null;
  number?: string | null;
  meterType?: MeterType | null;
  indexes?: Array<IndexesFrontend>;
}

interface Meters {
  electricity?: ElectricityMeterFrontend;
  gas?: GasMeterFrontend;
}

export interface MetersForm {
  electricity: FormGroup<ElectricityMeterFormGroup>;
  gas: FormGroup<GasMeterFormGroup>;
}

// We need to use different interface as for local storage data, so this is new interface for form in the new meters component.
// Reason is that we can't save File object to local storage.
export interface NewMetersStepForm {
  meters: Meters;
  dre: NewMetersStepFormDre;
}

export interface NewMetersStepFormDre {
  dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  fileForPartialStatus: File | null;
  fileForFullStatus: File | null;
}

// This is the interface for the data that we are mapped from backend and they are saved in the local storage.
export interface NewMetersStep {
  step: number;
  meters: Meters;
  dre: NewMetersStepDre;
}

export interface NewMetersStepDre {
  dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  fileName: string;
}

export type NewMetersFormFormGroup = FormUtils.FormGroupControls<NewMetersStepForm>;
export type ElectricityMeterFormGroup = FormUtils.FormGroupControls<ElectricityMeterFrontend>;
export type GasMeterFormGroup = FormUtils.FormGroupControls<GasMeterFrontend>;
export type IndexesFormGroup = FormUtils.FormGroupControls<IndexesFrontend>;
