import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { filter, forkJoin, Observable, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { INITIAL_MOVE_STATE, InvoicingMethod, MoveDTO, MoveFormFrontend } from '@app/core/state/move.state';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { MoveAddressComponent } from '@app/modules/customer-zone/move/components/move-form/parts/move-address/move-address.component';
import { Address } from '@app/modules/customer-zone/move/models/movein.interface';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { TranslateModule } from '@ngx-translate/core';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { InvoicingMethodComponent } from '@app/modules/customer-zone/move/components/move-form/parts/invoicing-method/invoicing-method.component';
import { BillingInfoForm, BillingInfoFormGroup } from './billing-info.interface';
@Component({
  selector: 'app-billing-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NavigationComponent,
    ReactiveFormsModule,
    MoveAddressComponent,
    TranslateModule,
    InvoicingMethodComponent,
  ],
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss'],
})
export class BillingInfoComponent extends MoveFormStep<MoveDTO> implements OnInit {
  readonly AlertType = AlertType;
  form: FormGroup<BillingInfoFormGroup>;
  billingEmail: string;
  defaultInvoiceDeliveryChannel: InvoicingMethod = InvoicingMethod.POST;
  submittedForm: boolean = false;

  constructor(protected readonly moveFormFacade: MoveFormFacade, private readonly facade: MainFacade) {
    super(moveFormFacade);

    this.form = new FormGroup<BillingInfoFormGroup>({
      billingAddress: this.moveFormFacade.getMoveFormService().getAddressFormGroup(),
      invoicingMethod: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.setFormValues();
  }

  get invoicingMethodControl(): FormControl<InvoicingMethod> {
    return this.form.controls.invoicingMethod;
  }

  setFormValues() {
    forkJoin([
      this.moveFormFacade.state$.pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      ),
      this.facade.loadBillingDetails(null, false).pipe(take(1)),
    ]).subscribe(([state, billingDetails]) => {
      const formValues = state?.form?.billingInfo;
      if (formValues?.billingAddress) {
        const values = formValues?.billingAddress;
        this.form.controls.billingAddress.patchValue({
          address: values?.address,
          number: values?.number,
          box: values?.box,
          zipCode: values?.zipCode,
          locality: values?.locality,
          country: this.facade.translate.instant('common.country.belgium'),
        });
      }
      this.billingEmail = billingDetails.email;

      const typeOfFacturation = formValues?.invoicingMethod
        ? formValues?.invoicingMethod
        : billingDetails.invoiceDeliveryChannel;
      this.invoicingMethodControl.setValue(typeOfFacturation);

      if (billingDetails.invoiceDeliveryChannel === InvoicingMethod.EMAIL) {
        this.form.controls.invoicingMethod.setValue(InvoicingMethod.EMAIL);
        this.form.controls.invoicingMethod.disable();
        this.defaultInvoiceDeliveryChannel = InvoicingMethod.EMAIL;
      } else if (formValues?.invoicingMethod) {
        this.form.controls.invoicingMethod.patchValue(formValues?.invoicingMethod);
      }
    });
  }

  onNextClickedDefault(): void {
    this.submittedForm = true;
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        this.moveFormFacade.next();
      });
    } else {
      markFormGroupTouched(this.form);
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getForm() });
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  saveFormData(): Observable<MoveDTO> {
    const moveFormService = this.moveFormFacade.getMoveFormService();
    const addressPayload: Address = moveFormService.getFormAddress(this.form.controls.billingAddress);
    const moveDTO: MoveDTO = moveFormService.updatedBillingInfoMoveOut(
      this.moveFormFacade.state$.value.moveDTO,
      addressPayload,
      this.form.controls.invoicingMethod.value
    );

    return this.moveFormFacade.update(moveDTO).pipe(take(1));
  }

  private getForm(): MoveFormFrontend {
    return {
      ...this.moveFormFacade?.state$?.value?.form,
      billingInfo: {
        ...(this.form.value as BillingInfoForm),
      },
    };
  }
}
