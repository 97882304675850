<section [formGroup]="newAddressStepFormGroup">
  <div>
    <h3 class="font-medium text-lg">
      {{ 'pages.move.energyType.myEnergies' | translate }}
    </h3>

    <app-alert
      *ngIf="newAddressStepFormGroup.controls.energyType.disabled"
      class="inline-block mt-4 mb-6 w-full"
      [flatDesign]="true"
      [alert]="{
      type: AlertType.info,
      content: {
        messageKey: 'pages.move.supplier.savedChoice',
      },
    }"
    ></app-alert>

    <ng-container formGroupName="energyType">
      <div
        *ngFor="let deliveryPoint of deliveryPoints; trackBy: trackDelPointById"
        class="flex rounded-4xl bg-grey-light mt-4 p-8 border border-silvermist items-center"
      >
        <p class="form-element input-checkbox w-full">
          <input
            type="checkbox"
            [formControlName]="deliveryPoint?.energyType?.toLocaleLowerCase()"
            [name]="deliveryPoint?.energyType"
            [id]="deliveryPoint?.energyType"
          />
          <label [for]="deliveryPoint?.energyType" class="!flex justify-between">
            <span>
              {{ 'pages.move.energyType.energy.' + deliveryPoint?.energyType.toLocaleLowerCase() | translate }}
            </span>
            <img
              [src]="'assets/img/icons/standalone/default/' + deliveryPoint?.energyType.toLocaleLowerCase() + '.svg'"
              class="inline-block mr-2"
              width="22"
              height="25"
              [alt]="deliveryPoint?.energyType"
            />
          </label>
        </p>
      </div>
    </ng-container>

    <app-input-error
      [cssClass]="'error inline-block w-full text-primary text-sm mt-4'"
      [condition]="
        newAddressStepFormGroup.controls.energyType.invalid &&
        (newAddressStepFormGroup.controls.energyType.touched || newAddressStepFormGroup.controls.energyType.dirty)
      "
      [errorMessage]="'errorMessages.move.requiredValuesMissing'"
    ></app-input-error>
  </div>

  <div class="mt-12">
    <h3 class="font-medium text-lg mb-4">{{ 'pages.move.energyType.startingDate' | translate }}</h3>
    <date-picker-field
      formControlName="requestedServiceDate"
      [fieldLabel]="'JJ/MM/AAAA'"
      [dateRange]="serviceDateRange"
      (dateChanged)="onDateSelection($event)"
    >
    </date-picker-field>
    <div
      *ngIf="
        newAddressStepFormGroup.controls.requestedServiceDate.invalid &&
        (newAddressStepFormGroup.controls.requestedServiceDate.touched ||
          newAddressStepFormGroup.controls.requestedServiceDate.dirty)
      "
      class="error inline-block w-full text-primary text-sm"
    >
      {{ 'errorMessages.requiredField' | translate }}
    </div>
  </div>
</section>
