import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MoveDTO, MoveState } from '@app/core/state/move.state';
import { MainFacade } from '@app/core/facade/main.facade';
import { map, Observable, of, take } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Address } from '@app/shared/models/address.interface';
import { MoveMeterTagComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-meter-tag/move-meter-tag.component';
import { MoveMeter } from '@app/modules/customer-zone/move/models/status.interface';
import { MoveDeliveryPointService } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/move-delivery-point.service';
import { MoveFormPaths } from '@app/core/models/paths';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { TranslateModule } from '@ngx-translate/core';
import { DeliveryPoint } from '../../../models/movein.interface';

@Component({
  selector: 'app-summary-aside',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MoveMeterTagComponent, TranslateModule],
  templateUrl: './summary-aside.component.html',
  styleUrls: ['./summary-aside.component.scss'],
})
export class SummaryAsideComponent implements OnChanges {
  @Input() moveState: MoveState;
  readonly: boolean;
  site$: Observable<Site>;
  meters: MoveMeter[];

  constructor(
    private moveFormFacade: MoveFormFacade,
    private facade: MainFacade,
    private moveDeliveryPointService: MoveDeliveryPointService
  ) {}

  ngOnChanges(): void {
    const moveDTO: MoveDTO = this.moveState?.moveDTO;
    const address = moveDTO?.sites?.[0]?.address;
    this.readonly = this.moveState.readonly;
    this.site$ = address?.street?.length ? this.getSiteFromMoveOut(address) : this.getSiteFromAPICall(moveDTO);
    this.meters =
      this.moveState.currentStepUrl === MoveFormPaths.myMeters
        ? this.getMetersFromServiceState()
        : this.getMetersFromMoveOut();
  }

  saveData() {
    this.moveFormFacade.emitDataSaving();
  }

  archive() {
    this.moveFormFacade
      .archive()
      .pipe(take(1))
      .subscribe(() => this.moveFormFacade.redirectToMoveDashboard());
  }

  private getSiteFromMoveOut(address: Address): Observable<Site> {
    return of({
      streetName: address?.street,
      streetNumber: address?.streetNumber,
      boxNumber: address?.box,
      town: address?.city,
      zipCode: address?.zip,
      country: address?.country,
    } as Site);
  }

  private getSiteFromAPICall(moveDTO: MoveDTO): Observable<Site> {
    return this.facade
      .loadSites(moveDTO.customerReference)
      .pipe(map((sites) => sites.find((site) => site.id === moveDTO.sites[0].siteId)));
  }

  private getMetersFromServiceState() {
    const deliveryPoints = this.moveDeliveryPointService.getListOfDeliveryPoints();
    return this.getMeters(deliveryPoints);
  }

  private getMetersFromMoveOut() {
    const deliveryPoints = this.moveState?.moveDTO?.sites?.[0]?.deliveryPoints;
    return this.getMeters(deliveryPoints);
  }

  private getMeters(deliveryPoints: DeliveryPoint[]): MoveMeter[] {
    return deliveryPoints?.map((dp) => ({
      energy: dp.energyType,
      ean: dp.code,
      number:
        dp?.meters?.length === 1
          ? dp?.meters?.[0]?.number
          : dp.meters.reduce((acc, meter) => {
              const meterNumber = meter?.number;
              if (meterNumber) {
                return acc ? `${acc}/${meterNumber}` : meterNumber;
              }
              return acc;
            }, ''),
    }));
  }
}
