import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { ActiveSitesIdPerReference, Site, SitesPerReference } from '../../models/site.interface';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private sites: Map<string, Site[]> = new Map<string, Site[]>();

  private sitesPerReference$: BehaviorSubject<SitesPerReference> = new BehaviorSubject<SitesPerReference>(null);
  private sitesPerReference: SitesPerReference;

  private activeSiteId$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private activeSiteId: string;

  private activeSiteIdPerReference$: BehaviorSubject<ActiveSitesIdPerReference> =
    new BehaviorSubject<ActiveSitesIdPerReference>(null);
  private activeSiteIdPerReference: ActiveSitesIdPerReference = {};

  constructor(private cookieService: CookieService, private utilsService: UtilsService, private http: HttpClient) {}

  public updateSitesPerReference(sitesPerReference: SitesPerReference, activeReference: string): void {
    this.sitesPerReference = sitesPerReference;
    this.sitesPerReference$.next(sitesPerReference);
    const activeSiteIdPerReference = this.prepareActiveSiteIdPerReference(sitesPerReference);
    this.updateActiveSiteIdPerReference(activeSiteIdPerReference);
    this.updateSiteId(activeReference, this.activeSiteIdPerReference[activeReference]);
  }

  public getSites(reference: string): Observable<Site[]> {
    if (this.sites.has(reference)) {
      return of(this.sites.get(reference));
    }
    return this.http
      .get<Site[]>(`/v1/customers/${reference}/sites`)
      .pipe(tap((sites: Site[]) => this.sites.set(reference, sites)));
  }

  public getSite(reference: string, siteId: string, loader: boolean = true): Observable<Site> {
    const site: Site =
      this.sitesPerReference &&
      this.sitesPerReference[reference] &&
      this.sitesPerReference[reference].find((s: Site) => s.id === siteId);
    if (site) {
      return of(site);
    }
    return this.getSiteFromApi(reference, siteId, loader).pipe(
      tap((s: Site) => {
        if (!this.sitesPerReference) {
          this.sitesPerReference = {};
          this.sitesPerReference[reference] = [{ ...s }];
        } else if (!this.sitesPerReference[reference]) {
          this.sitesPerReference[reference] = [{ ...s }];
        } else {
          const sites = this.sitesPerReference[reference].filter((singleSite) => singleSite.id !== siteId);
          sites.push(s);
          this.sitesPerReference[reference] = sites;
        }
      })
    );
  }

  public getSiteFromApi(reference: string, siteId: string, loader: boolean) {
    const params: HttpParams = new HttpParams().set('loader', loader);
    return this.http.get<Site>(`/v1/customers/${reference}/sites/${siteId}`, { params }).pipe(shareReplay(1));
  }

  public getSitesPerReference(): Observable<SitesPerReference> {
    return this.sitesPerReference$;
  }

  public prepareActiveSiteIdPerReference(sitesPerReference: SitesPerReference) {
    const cookieName: string = this.utilsService.activeInfoCookieName;
    let activeSiteIdPerReference = this.utilsService.getCookieValue(cookieName, 'activeSitePerRef');
    if (
      !activeSiteIdPerReference ||
      (Object.keys(activeSiteIdPerReference).length === 0 && activeSiteIdPerReference.constructor === Object) ||
      window.localStorage.getItem('binding')
    ) {
      activeSiteIdPerReference = {};
      Object.keys(sitesPerReference).forEach((reference: string) => {
        activeSiteIdPerReference[reference] = sitesPerReference[reference][0].id;
      });
    }
    return activeSiteIdPerReference;
  }

  public setCookieWithActiveSiteIdPerReference(activeSiteIdPerReference: ActiveSitesIdPerReference) {
    const cookieName: string = this.utilsService.activeInfoCookieName;
    let cookie: any = this.utilsService.getCookieValue(cookieName);
    cookie = cookie ? cookie : {};
    cookie.activeSitePerRef = activeSiteIdPerReference;
    this.utilsService.setCookieValue(cookieName, cookie);
  }

  public updateSiteId(reference: string, siteId: string) {
    this.activeSiteIdPerReference[reference] = siteId;
    this.activeSiteId$.next([reference, this.activeSiteIdPerReference[reference]]);
    this.activeSiteId = this.activeSiteIdPerReference[reference];
    this.updateActiveSiteIdPerReference(this.activeSiteIdPerReference);
  }

  public updateActiveSiteIdPerReference(activeSiteIdPerReference: ActiveSitesIdPerReference): void {
    this.setCookieWithActiveSiteIdPerReference(activeSiteIdPerReference);
    this.activeSiteIdPerReference = activeSiteIdPerReference;
    this.activeSiteIdPerReference$.next(activeSiteIdPerReference);
  }

  public getActiveSiteId(reference): Observable<string[]> {
    this.activeSiteId$.next([reference, this.activeSiteIdPerReference[reference]]);
    this.activeSiteId = this.activeSiteIdPerReference[reference];
    return this.activeSiteId$;
  }

  public getActiveSiteIdPerReference(): Observable<ActiveSitesIdPerReference> {
    return this.activeSiteIdPerReference$;
  }
}
