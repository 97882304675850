import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MoveAddressFormGroup } from '../../steps/new-address/new-address.interface';

@Component({
  selector: 'app-move-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslateModule, InputErrorComponent],
  templateUrl: './move-address.component.html',
  styleUrls: ['./move-address.component.scss'],
})
export class MoveAddressComponent implements OnInit {
  @Input() submittedForm: boolean;

  constructor(private controlContainer: ControlContainer) {}

  get addressFormGroup(): FormGroup<MoveAddressFormGroup> {
    return this.controlContainer.control as FormGroup<MoveAddressFormGroup>;
  }

  isControlInValid(value: string): boolean {
    return this.submittedForm && this.addressFormGroup.get(value).invalid && this.controlInterracted(value);
  }

  private controlInterracted(value: string): boolean {
    return this.addressFormGroup.get(value).touched || this.addressFormGroup.get(value).dirty;
  }

  ngOnInit(): void {}
}

_('errorMessages.move.fieldsBelowAreRequired');
