import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';
import { Meter, MoveDTO } from '@app/core/state/move.state';
import { SiteStatusCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';

@Injectable({
  providedIn: 'root',
})
export class MoveOutService {
  constructor(private http: HttpClient) {}

  // @todo: to review
  get(reference: string, siteId: string, moveId: string, params: HttpParams = null): Observable<MoveDTO> {
    return this.http
      .get<MoveDTO>(`/v1/move/${reference}/sites/${siteId}/moves/${moveId}`, { params })
      .pipe(catchError((err): Observable<MoveDTO> => throwError(err)));
  }

  // Move out endpoint
  update(
    reference: string,
    siteId: string,
    moveOutId: string,
    moveDTO: MoveDTO,
    params: HttpParams = null
  ): Observable<MoveDTO> {
    return this.http
      .put<MoveDTO>(`/v1/move/${reference}/sites/${siteId}/moves/${moveOutId}`, moveDTO, { params })
      .pipe(catchError((err): Observable<MoveDTO> => throwError(err)));
  }

  archiveMove(reference: string, siteId: string, moveOutId: string): Observable<void> {
    return this.http.post<void>(`/v1/move/${reference}/sites/${siteId}/moves/${moveOutId}/archive`, {}, {});
  }

  // @todo: to review
  getStatus(reference: string): Observable<SiteStatusCuzoApi[]> {
    return this.http
      .get<SiteStatusCuzoApi[]>(`/v1/move/${reference}/status`)
      .pipe(catchError((err): Observable<SiteStatusCuzoApi[]> => throwError(err)));
  }

  getRegistration(reference: string, moveOutId: string, params: HttpParams = null): Observable<MoveInRegistration[]> {
    return this.http
      .get<MoveInRegistration[]>(`/v1/move/${reference}/move/${moveOutId}/registration`, { params })
      .pipe(catchError((err): Observable<MoveInRegistration[]> => throwError(err)));
  }

  updateRegistration(
    reference: string,
    id: string,
    payload: any,
    params: HttpParams = null
  ): Observable<MoveInRegistration> {
    return this.http
      .put<MoveInRegistration>(`/v1/move/${reference}/registration/${id}`, payload, { params })
      .pipe(catchError((err): Observable<MoveInRegistration> => throwError(err)));
  }

  createAmendment(reference: string, siteId: string, moveDTO: MoveDTO, params: HttpParams = null): Observable<any> {
    return this.http
      .put(`/v1/move/${reference}/sites/${siteId}/moves/${moveDTO.id}/amendment`, {}, { params })
      .pipe(catchError((err): Observable<MoveDTO> => throwError(err)));
  }

  createRegistration(
    reference: string,
    payload: MoveInRegistration,
    params: HttpParams = null
  ): Observable<MoveInRegistration> {
    return this.http
      .post<MoveInRegistration>(`/v1/move/${reference}/registration`, payload, { params })
      .pipe(catchError((err): Observable<MoveInRegistration> => throwError(() => err)));
  }

  linkMoveInRegistration(
    reference: string,
    siteId: string,
    moveDTO: MoveDTO,
    registrationId: string,
    params: HttpParams = null
  ): Observable<MoveInRegistration> {
    return this.http
      .put<MoveInRegistration>(
        `/v1/move/${reference}/sites/${siteId}/moves/${moveDTO.id}/registration/${registrationId}/link`,
        {},
        { params }
      )
      .pipe(catchError((err): Observable<MoveInRegistration> => throwError(err)));
  }

  // @todo: to review
  create(reference: string, siteId: string, moveDTO: MoveDTO, params: HttpParams = null): Observable<MoveDTO> {
    return this.http.post<MoveDTO>(`/v1/move/${reference}/sites/${siteId}/moves`, moveDTO, { params });
  }

  getAvailableMeters(
    reference: string,
    siteId: string,
    moveId: string,
    params: HttpParams = null
  ): Observable<Meter[]> {
    return this.http
      .get<Meter[]>(`/v1/move/${reference}/sites/${siteId}/move/${moveId}/available-meters`, { params })
      .pipe(
        map((result: PreSwitchLight[]) => {
          if (result.length === 0) {
            throw new Error('Result is an empty array');
          }
          return result;
        }),
        catchError((err): Observable<Meter[]> => throwError(err)),
        shareReplay(1)
      );
  }

  preSwitchLight(
    reference: string,
    siteId: string,
    moveId: string,
    params: HttpParams = null
  ): Observable<PreSwitchLight[]> {
    return this.http.get<any>(`/v1/move/${reference}/sites/${siteId}/move/${moveId}/pswl`, { params }).pipe(
      map((result: PreSwitchLight[]) => {
        if (result.length === 0) {
          throw new Error('Result is an empty array');
        }
        return result;
      }),
      catchError((err): Observable<any> => throwError(err))
    );
  }

  uploadHandoverMoveOutDocument(
    reference: string,
    siteId: string,
    moveId: string,
    file: File,
    params: HttpParams = null
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    return this.http.post<any>(`/v1/move/${reference}/sites/${siteId}/move/${moveId}/documents`, formData, { params });
  }

  uploadHandoverMoveInDocument(
    reference: string,
    moveInId: string,
    file: File,
    params: HttpParams = null
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    return this.http.post<any>(`/v1/move/${reference}/registration/${moveInId}/documents`, formData, { params });
  }

  // This api only trigger validation on backend we don't need to send or return anything
  finalizeMoveOut(reference: string, moveId: string): Observable<void> {
    return this.http.post<void>(`/v1/move/${reference}/move/${moveId}/validation`, {});
  }
}
