<div class="step-wrapper">
  <div class="step mb-16" [formGroup]="formGroup">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.newOccupant.title' | translate }}
    </h2>

    <p class="font-medium mt-4">{{ 'pages.move.newOccupant.questions.hasNewOccupant.label' | translate }}</p>
    <p class="form-element input-radio mt-4">
      <input type="radio" id="yesNewOccupant" [value]="true" formControlName="hasNewOccupant" />
      <label for="yesNewOccupant"> {{ 'pages.move.newOccupant.questions.hasNewOccupant.yes' | translate }} </label>
    </p>
    <p class="form-element input-radio mt-4">
      <input type="radio" id="noNewOccupant" [value]="false" formControlName="hasNewOccupant" />
      <label for="noNewOccupant">{{ 'pages.move.newOccupant.questions.hasNewOccupant.no' | translate }}</label>
    </p>
    <app-input-error
      [cssClass]="'error inline-block w-full text-primary text-sm mt-2'"
      [condition]="
        submittedForm && hasNewOccupantControl.invalid && (hasNewOccupantControl.touched || hasNewOccupantControl.dirty)
      "
      [errorMessage]="'errorMessages.move.fieldsBelowAreRequired'"
    ></app-input-error>

    <div *ngIf="formGroup.controls.hasNewOccupant.value" @ShowHide>
      <p class="mt-12">{{ 'pages.move.newOccupant.subtitleOccupantInfo' | translate }}</p>
      <div class="flex flex-col sm:flex-row gap-4 sm:gap-8">
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>{{ 'pages.move.newOccupant.firstName' | translate }}</label>
          <input type="text" formControlName="firstName" placeholder="John" />
        </p>
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>{{ 'pages.move.newOccupant.lastName' | translate }}</label>
          <input type="text" formControlName="lastName" placeholder="Smith" />
        </p>
      </div>

      <p class="w-full sm:w-1/2 form-element input-text mt-8">
        <label>{{ 'pages.move.newOccupant.phone' | translate }}</label>
        <input type="text" formControlName="mobilePhone" placeholder="+32 2 123 4567" />
      </p>
    </div>
  </div>
</div>
<app-navigation
  [stepValidity]="true"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
></app-navigation>
