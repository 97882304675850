<div [formGroup]="dreFormGroup">
  <app-input-error
    [cssClass]="'error inline-block w-full text-primary text-sm mt-4'"
    [condition]="
      dreFormGroup.get('dreStatus').invalid &&
      (dreFormGroup.get('dreStatus').touched || dreFormGroup.get('dreStatus').dirty)
    "
    [errorMessage]="'errorMessages.move.requiredValuesMissing'"
  ></app-input-error>

  <div class="grid gap-8 mt-8">
    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.full.title' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="dreStatusControl.setValue($event)"
    >
      <div class="p-10 pt-2">
        <te-input-file
          id="file_full"
          fileWrapperClass="md:!w-4/5"
          formControlName="fileForFullStatus"
          fileType="application/pdf, .jpg, .jpeg, .png"
          [placeholder]="'pages.move.dreDocument.placeholder' | translate"
          [buttonTitle]="'pages.move.dreDocument.buttonTitle' | translate"
          [loading]="uploadInProgress"
          [maxFileSize]="maxFileSizeMB"
          [required]="true"
          [errorMessage]="fileErrorMessages"
          [markTouch]="dreFormGroup.get('fileForFullStatus').touched"
          (fileChanged)="onFileSelection($event)"
        >
        </te-input-file>
      </div>
    </app-expandable-radio-btn>

    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.partial.title' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="dreStatusControl.setValue($event)"
    >
      <div class="p-10 pt-2">
        <te-input-file
          id="file_partial"
          fileWrapperClass="md:!w-4/5"
          formControlName="fileForPartialStatus"
          fileType="application/pdf, .jpg, .jpeg, .png"
          [placeholder]="'pages.move.dreDocument.placeholder' | translate"
          [buttonTitle]="'pages.move.dreDocument.buttonTitle' | translate"
          [loading]="uploadInProgress"
          [maxFileSize]="maxFileSizeMB"
          [required]="true"
          [errorMessage]="fileErrorMessages"
          [markTouch]="dreFormGroup.get('fileForPartialStatus').touched"
          (fileChanged)="onFileSelection($event)"
        >
        </te-input-file>
      </div>
    </app-expandable-radio-btn>

    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.noDocument.title' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="dreStatusControl.setValue($event)"
    >
    </app-expandable-radio-btn>
  </div>

  <app-alert
    class="inline-block mt-16 w-full"
    [flatDesign]="true"
    [alert]="{
          type: AlertType.info,
          content: {
            messageKey: 'pages.move.dreDocument.alert.documentUploading',
          },
        }"
  ></app-alert>
</div>
