<div [formGroup]="metersFormGroup">
  <div formGroupName="gas">
    <div class="flex mt-8 items-center mb-8">
      <img ngSrc="./assets/img/icons/standalone/default/gas.svg" height="30" width="24" />
      <h3 class="text-lg ml-4">{{ 'pages.move.energyType.energy.gas' | translate }}</h3>
    </div>
    <p>
      {{ 'pages.move.newMeters.gas.explanation' | translate }}
    </p>

    <section class="border-b border-grey/35 pb-12 mb-12">
      <div class="my-8">
        <p class="form-element input-checkbox errors mt-4">
          <input type="checkbox" formControlName="noMeter" id="noMeter" />
          <label for="noMeter">
            {{ 'pages.move.newMeters.gas.noMeterCheckbox' | translate }}
          </label>
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-text">
          <te-input-ean
            [label]="'general.eanCode' | translate"
            formControlName="ean"
            id="ean"
            [errorMessage]="eanErrorTranslations"
          >
          </te-input-ean>
        </p>
        <p
          class="w-full sm:w-1/2 form-element input-text mt-4"
          [class.errors]="meterNumberControl.invalid && meterNumberInteracted"
        >
          <label>{{ 'general.meterNumber' | translate }}</label>
          <input type="text" formControlName="number" placeholder="XXXXXXXX" />
          <app-input-error
            [condition]="submittedForm && meterNumberControl.errors?.required && meterNumberInteracted"
            [errorMessage]="'errorMessages.requiredField'"
          ></app-input-error>
        </p>
      </div>
    </section>

    <section *ngIf="indexesControls">
      <div class="flex flex-wrap indexes">
        <div *ngFor="let indexControl of indexesControls">
          <app-input-meter-index
            [style]="InputMeterStyle.INLINE"
            [type]="indexControl?.controls.type?.value"
            [value]="indexControl?.controls.value?.value"
            [control]="indexControl"
            [newType]="indexControl.controls.timeFrame.value"
            [unit]="'pages.move.myMeters.unit.GAS' | translate"
            [label]="
              'components.estimatedIndexDetail.form.meterInput.' +
                indexControl.controls.timeFrame.value +
                '.' +
                indexControl.controls.type.value +
                '.label' | translate
            "
          >
          </app-input-meter-index>
        </div>
      </div>
    </section>
  </div>
</div>
