<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.newAddress.title' | translate }}
    </h2>
    <span>
      {{ 'pages.move.newAddress.subtitle' | translate }}
    </span>

    <div [formGroup]="form">
      <section>
        <h3 class="font-medium text-2xl mt-10">
          {{ 'pages.move.address.newBillingAddressTitle' | translate }}
        </h3>
        <app-move-address formGroupName="billingAddress" [submittedForm]="submittedForm"> </app-move-address>
      </section>
      <section>
        <app-invoicing-method
          [billingEmail]="billingEmail"
          [submittedForm]="submittedForm"
          [defaultInvoiceDeliveryChannel]="defaultInvoiceDeliveryChannel"
        ></app-invoicing-method>
      </section>
    </div>
  </div>

  <app-navigation [stepValidity]="true" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
  </app-navigation>
</div>
