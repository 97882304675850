import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoveProgressComponent } from '@app/modules/customer-zone/move/components/move-form/move-progress/move-progress.component';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { LeavingBannerComponent } from '@app/modules/customer-zone/move/components/move-form/leaving-banner/leaving-banner.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { distinctUntilChanged, filter, skip, Subject, takeUntil } from 'rxjs';
import { SummaryAsideComponent } from '@app/modules/customer-zone/move/components/move-form/summary-aside/summary-aside.component';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { MoveState } from '@app/core/state/move.state';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { MoveDeliveryPointService } from './steps/my-meters/move-delivery-point.service';

@Component({
  selector: 'app-move-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    TranslateModule,
    MoveProgressComponent,
    NgOptimizedImage,
    LeavingBannerComponent,
    SummaryAsideComponent,
    AlertComponent,
  ],
  templateUrl: './move-form.component.html',
  styleUrls: ['./move-form.component.scss'],
})
export class MoveFormComponent implements OnInit, OnDestroy {
  showSumUpBox = false;
  AlertType = AlertType;
  showResponseErrorAlert = false;
  protected readonly LoaderStatus = LoaderStatus;
  private readonly notifier: Subject<void> = new Subject<void>();

  constructor(
    readonly moveFormFacade: MoveFormFacade,
    private readonly facade: MainFacade,
    private readonly router: Router,
    private readonly moveDeliveryPointService: MoveDeliveryPointService
  ) {}

  ngOnInit(): void {
    this.facade.reference$
      .pipe(
        filter((ref: string) => !!ref),
        skip(1),
        distinctUntilChanged(),
        takeUntil(this.notifier)
      )
      .subscribe(() => this.router.navigate([MoveFormFacade.MOVE_URL]));

    this.moveFormFacade.state$.pipe(takeUntil(this.notifier)).subscribe((moveState: MoveState) => {
      this.showResponseErrorAlert = moveState.responseError;
    });
  }

  ngOnDestroy(): void {
    this.moveDeliveryPointService.resetDeliveryPoints();
  }
}
