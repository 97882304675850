<div
  *ngIf="defaultInvoiceDeliveryChannel !== InvoicingMethod.EMAIL; else emailInvoicingInfo"
  [class.disabled]="invoicingMethodControl.disabled"
>
  <ng-container *ngIf="billingEmail; else postFacturationInfo">
    <h3 class="text-2xl font-medium text-grey-dark mt-12 mb-4">
      {{ 'pages.move.emailInvoicingInfo.title' | translate }}
    </h3>
    <app-input-error
      [cssClass]="'mb-8'"
      [condition]="invoicingMethodControl.invalid && (invoicingMethodControl.touched || invoicingMethodControl.dirty)"
      [errorMessage]="'errorMessages.move.requiredValuesMissing'"
    ></app-input-error>

    <div class="grid gap-8">
      <app-expandable-radio-btn
        name="invoicingMethod"
        [label]="'pages.move.invoingChoices.email' | translate"
        [value]="InvoicingMethod.EMAIL"
        [selectedValue]="invoicingMethodControl.value"
        (selectedValueChange)="onInvoicingMethodChange($event)"
      >
        <div class="p-10 pt-2">
          {{ 'pages.move.invoingChoices.currentEmaiLabel' | translate }}
          <span class="text-grey-dark60">{{ billingEmail }}</span>
        </div>
      </app-expandable-radio-btn>

      <app-expandable-radio-btn
        name="invoicingMethod"
        [label]="'pages.move.invoingChoices.post' | translate"
        [value]="InvoicingMethod.POST"
        [selectedValue]="invoicingMethodControl.value"
        (selectedValueChange)="onInvoicingMethodChange($event)"
      >
      </app-expandable-radio-btn>
    </div>
  </ng-container>
</div>

<ng-template #emailInvoicingInfo>
  <h3 class="text-2xl font-medium text-grey-dark mt-12">
    {{ 'pages.move.emailInvoicingInfo.title' | translate }}
  </h3>
  <h4 class="text-base font-medium mt-4 mb-2">
    {{ 'pages.move.emailInvoicingInfo.subtitle' | translate }}
  </h4>
  <p class="text-sm">
    {{ 'pages.move.emailInvoicingInfo.content' | translate }}
  </p>
</ng-template>

<ng-template #postFacturationInfo>
  <h3 class="text-2xl font-medium text-grey-dark mt-12">
    {{ 'pages.move.postInvoicingInfo.title' | translate }}
  </h3>
  <p class="text-sm">
    {{ 'pages.move.postInvoicingInfo.content' | translate }}
  </p>
</ng-template>
