import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import dayjs from 'dayjs';
import { dateRangeValidator } from '@app/shared/date-picker-field/validators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeliveryPoint } from '@app/modules/customer-zone/move/models/movein.interface';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import { NewAddressStepFromGroup } from '../new-address.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-energy-type',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerFieldModule,
    TranslateModule,
    InputErrorComponent,
    AlertComponent,
  ],
  templateUrl: './energy-type.component.html',
  styleUrls: ['./energy-type.component.scss'],
})
export class EnergyTypeComponent implements OnInit {
  @Input() deliveryPoints: DeliveryPoint[];
  serviceDateRange: { min: NgbDateStruct; max: NgbDateStruct } = { min: null, max: null };
  readonly AlertType = AlertType;
  constructor(private controlContainer: ControlContainer) {}

  get newAddressStepFormGroup(): FormGroup<NewAddressStepFromGroup> {
    return this.controlContainer.control as FormGroup<NewAddressStepFromGroup>;
  }

  ngOnInit(): void {
    this.serviceDateRange = this.setDateRange();
  }

  onDateSelection(date: string): void {
    this.newAddressStepFormGroup.controls.requestedServiceDate.setValue(date);
  }

  private setDateRange(): { min: NgbDateStruct; max: NgbDateStruct } {
    const today = dayjs();

    const minDate = today.subtract(30, 'day');
    const maxDate = today.add(30, 'day');

    this.setValidators(minDate, maxDate);

    const formatDate = (date: dayjs.Dayjs) => {
      return {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      };
    };

    return {
      min: formatDate(minDate),
      max: formatDate(maxDate),
    };
  }

  private setValidators(minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs) {
    this.newAddressStepFormGroup.controls.requestedServiceDate.setValidators([dateRangeValidator(minDate, maxDate)]);
    this.newAddressStepFormGroup.updateValueAndValidity();
  }

  trackDelPointById(_: number, item: DeliveryPoint): string {
    return item.id;
  }
}

_('pages.move.energyType.energy.electricity');
_('pages.move.energyType.energy.gas');
