import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { UtilsService } from '@app/shared/utils/utils.service';
import { Region } from '@app/shared/models/region.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { RegisterService } from '@app/modules/customer-zone/move/services/form/register.service';
import { MoveRegister } from '@app/core/state/move.state';
import { combineLatest, startWith } from 'rxjs';
import { ExpandableCheckboxBtnComponent } from '@app/modules/customer-zone/move/components/move-form/parts/expandable-checkbox-btn/expandable-checkbox-btn.component';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as FormUtils from '@app/shared/utils/utils.form';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ElectricityMeterFormGroup, IndexesFormGroup, MetersForm, MeterTypeOptions } from '../new-meters.interface';
import { MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import dayjs from 'dayjs';
// Used for merging smart meter and meter type to one input
export const SMART = 'SMART_';

@Component({
  selector: 'app-electricity-meter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    TranslateModule,
    DatePickerFieldModule,
    ExpandableCheckboxBtnComponent,
    InputErrorComponent,
  ],
  templateUrl: './electricity-meter.component.html',
  styleUrls: ['./electricity-meter.component.scss'],
})
export class ElectricityMeterComponent implements OnInit {
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  @Input() registration: MoveInRegistration;
  @Input() moveInDate: Date;
  @Input() submittedForm: boolean;

  readonly Region = Region;
  readonly InputMeterStyle = InputMeterStyle;
  readonly meterTypeOptions = MeterTypeOptions;
  eanErrorTranslations = this.moveFormFacade.getMoveFormService().getErrorMessagesForEan();
  region: Region;
  readonly solarPanelKvaRange = { min: 0.1, max: 10 };
  readonly solarPanelKvaValidators = [
    Validators.min(this.solarPanelKvaRange.min),
    Validators.max(this.solarPanelKvaRange.max),
    Validators.required,
  ];

  constructor(
    private moveFormFacade: MoveFormFacade,
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private destroyRef: DestroyRef
  ) {}

  get metersFormGroup(): FormGroup<MetersForm> {
    return this.controlContainer.control as FormGroup<MetersForm>;
  }

  get solarPanelsKvaControl(): FormControl<number> {
    return this.metersFormGroup.controls.electricity.controls.solarPanelsKva;
  }

  get electricityControls(): ElectricityMeterFormGroup {
    return this.metersFormGroup.controls.electricity.controls;
  }

  get solarPanelsKvaInteracted(): boolean {
    return this.solarPanelsKvaControl.touched || this.solarPanelsKvaControl.dirty;
  }

  ngOnInit(): void {
    this.region = UtilsService.getRegion(+this.registration?.sites?.[0]?.address?.zip);
    const electricityGroup = this.createElectricityFormGroup();
    this.metersFormGroup.setControl('electricity', electricityGroup);
    this.setFormValidity();
    this.subscribeToFormChanges();
    this.emitFormValidity();
    this.subscribeToLangChange();
  }

  private createElectricityFormGroup(): FormGroup<ElectricityMeterFormGroup> {
    const state = this.moveFormFacade.state$.value;
    const electricityForm = state?.form?.newMeters?.meters?.electricity;
    return this.formBuilder.group<ElectricityMeterFormGroup>({
      noMeter: new FormControl(electricityForm?.noMeter || false),
      ean: new FormControl(electricityForm?.ean || null),
      number: new FormControl(electricityForm?.number || null),
      //meter type and smart meter form controls are not showed in the UI, we are populating them based on the smartMeterAndMeterType
      smartMeterAndMeterType: new FormControl(electricityForm?.smartMeterAndMeterType, Validators.required),
      meterType: new FormControl(electricityForm?.meterType || null, [Validators.required]),
      smartMeter: new FormControl(electricityForm?.smartMeter || false),
      exclusiveNight: new FormControl(electricityForm?.exclusiveNight || false),
      peakPower: new FormControl(electricityForm?.peakPower || null, [Validators.min(2.5), Validators.max(30)]),
      hasSolarPanels: new FormControl(electricityForm?.hasSolarPanels || false),
      solarPanelsKva: new FormControl(electricityForm?.solarPanelsKva || null, this.solarPanelKvaValidators),
      solarPanelsInstallationDateStarting2024: new FormControl(
        electricityForm?.solarPanelsInstallationDateStarting2024 || false
      ),
      injectionTariff: new FormControl(electricityForm?.injectionTariff || false),
      indexes: this.formBuilder.array<FormGroup<IndexesFormGroup>>(
        electricityForm?.indexes?.length ? this.buildIndexesArray(electricityForm?.indexes, null) : []
      ),
    });
  }

  private subscribeToLangChange(): void {
    this.moveFormFacade.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.eanErrorTranslations = this.moveFormFacade.getMoveFormService().getErrorMessagesForEan();
    });
  }

  private subscribeToFormChanges(): void {
    const electricityFormGroup = this.metersFormGroup.controls.electricity;
    electricityFormGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => this.setFormValidity());
    this.rebuildIndexesFieldsOnValuesChanges(electricityFormGroup);
  }

  private rebuildIndexesFieldsOnValuesChanges(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    combineLatest([
      electricityFormGroup.controls.smartMeterAndMeterType.valueChanges.pipe(
        startWith(electricityFormGroup.controls.smartMeterAndMeterType.value)
      ),
      electricityFormGroup.controls.exclusiveNight.valueChanges.pipe(
        startWith(electricityFormGroup.controls.exclusiveNight.value)
      ),
      electricityFormGroup.controls.noMeter.valueChanges.pipe(startWith(electricityFormGroup.controls.noMeter.value)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!electricityFormGroup.controls.smartMeterAndMeterType.value) {
          return;
        }

        this.prefillSmartMeterAndMeterType(electricityFormGroup);

        const existingIndexes = electricityFormGroup.controls.indexes;
        const indexes = this.registerService.buildMeterReadingRegisters(
          EnergyType.ELECTRICITY,
          electricityFormGroup.controls.meterType.value,
          electricityFormGroup.controls.exclusiveNight.value,
          electricityFormGroup.controls.smartMeter.value
        );
        const indexesFormGroup = this.buildIndexesArray(indexes, existingIndexes);
        this.setFormArrayIndexes(electricityFormGroup, indexesFormGroup);
      });
  }

  private prefillSmartMeterAndMeterType(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    const smartMeterAndMeterType = electricityFormGroup.controls.smartMeterAndMeterType.value;
    const smartMeterTypeOptions = Object.keys(MeterTypeOptions).filter((key) => key.includes(SMART));
    if (smartMeterTypeOptions.includes(smartMeterAndMeterType)) {
      const [, meterType] = smartMeterAndMeterType.split('_');
      electricityFormGroup.controls.smartMeter.setValue(true);
      electricityFormGroup.controls.meterType.setValue(meterType as MeterType);
    } else {
      const [meterType] = smartMeterAndMeterType.split('_');
      electricityFormGroup.controls.smartMeter.setValue(false);
      electricityFormGroup.controls.meterType.setValue(meterType as MeterType);
    }
  }

  private buildIndexesArray(
    indexes: MoveRegister[],
    existingIndexes: FormArray<FormGroup>
  ): Array<FormGroup<IndexesFormGroup>> {
    return indexes.map((index) => this.createIndexFormGroup(index, existingIndexes));
  }

  private createIndexFormGroup(
    index: MoveRegister,
    existingIndexes: FormArray<FormGroup>
  ): FormGroup<IndexesFormGroup> {
    const existingIndex = existingIndexes?.value?.find(
      (ei: MoveRegister) => ei.timeFrame === index.timeFrame && ei.type === index.type
    );
    const [unit, decimal] = index?.value ? index?.value?.split('.') : [null, null];
    const value = index?.value
      ? index?.value
      : index?.['unit'] || index?.['decimal']
      ? `${index?.['unit'] ?? 0}.${index?.['decimal'] ?? 0}`
      : null;
    return this.formBuilder.group<IndexesFormGroup>({
      timeFrame: new FormControl(index?.timeFrame),
      type: new FormControl(index?.type),
      unit: new FormControl(index?.['unit'] ?? unit ?? existingIndex?.unit ?? null, this.getIndexesValidators()),
      decimal: new FormControl(index?.['decimal'] ?? decimal ?? existingIndex?.decimal ?? null),
      value: new FormControl(index?.value ?? value ?? existingIndex?.value ?? null),
    });
  }

  private setFormArrayIndexes(
    electricityFormGroup: FormGroup<ElectricityMeterFormGroup>,
    indexes: FormGroup<IndexesFormGroup>[]
  ) {
    const indexesArray: FormArray<FormGroup> = electricityFormGroup.controls.indexes;
    indexesArray.clear();
    indexes.forEach((item) => indexesArray.push(item));
  }

  private setFormValidity(): void {
    const electricityFormGroup = this.metersFormGroup.controls.electricity;
    this.updateValidatorsForFlanders(electricityFormGroup);
    this.updateValidatorsBasedOnSolarPanels(electricityFormGroup);
    this.updateValidatorsForWallonia(electricityFormGroup);
    this.updateValidatorsForHasMeter(electricityFormGroup);
  }

  private updateValidatorsForHasMeter(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    const meterNumber = electricityFormGroup.controls.number;
    const smartMeterAndMeterType = electricityFormGroup.controls.smartMeterAndMeterType;
    const meterType = electricityFormGroup.controls.meterType;

    if (electricityFormGroup.controls.noMeter.value) {
      FormUtils.removeValidator(meterNumber, [Validators.required]);
      FormUtils.removeValidator(smartMeterAndMeterType, [Validators.required]);
      FormUtils.removeValidator(meterType, [Validators.required]);
    } else {
      FormUtils.addValidator(meterNumber, [Validators.required]);
      FormUtils.addValidator(smartMeterAndMeterType, [Validators.required]);
      FormUtils.addValidator(meterType, [Validators.required]);
    }
  }

  private updateValidatorsForFlanders(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    const peakPowerControl = electricityFormGroup.controls.peakPower;
    if (this.region === Region.flanders && electricityFormGroup.controls.smartMeter?.value) {
      FormUtils.addValidator(peakPowerControl, [Validators.required]);
    } else {
      FormUtils.removeValidator(peakPowerControl, [Validators.required]);
    }
  }

  private updateValidatorsBasedOnSolarPanels(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    const solarPanelsKvaControl = electricityFormGroup.controls.solarPanelsKva;
    if (electricityFormGroup.controls.hasSolarPanels.value) {
      FormUtils.addValidator(solarPanelsKvaControl, this.solarPanelKvaValidators);
    } else {
      FormUtils.removeValidator(solarPanelsKvaControl, this.solarPanelKvaValidators);
    }
  }

  private updateValidatorsForWallonia(electricityFormGroup: FormGroup<ElectricityMeterFormGroup>): void {
    const solarPanelsInstalationDateControl = electricityFormGroup.controls.solarPanelsInstallationDateStarting2024;
    if (
      this.region === Region.wallonia &&
      electricityFormGroup.controls.smartMeter?.value &&
      electricityFormGroup.controls.hasSolarPanels.value
    ) {
      FormUtils.addValidator(solarPanelsInstalationDateControl, [Validators.required]);
    } else {
      FormUtils.removeValidator(solarPanelsInstalationDateControl, [Validators.required]);
    }
  }

  private emitFormValidity(): void {
    const electricityGroup = this.metersFormGroup.controls.electricity;
    this.formValidityEmitter.emit(electricityGroup.valid);
    electricityGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.formValidityEmitter.emit(electricityGroup.valid));
  }

  private getIndexesValidators(): ValidatorFn[] {
    const moveInDateDayJs = dayjs(this.moveInDate);
    return moveInDateDayJs.isSame(dayjs()) || moveInDateDayJs.isBefore(dayjs()) ? [Validators.required] : [];
  }

  onHasSolarPanelChanges(event: Event) {
    const { checked }: { checked: boolean } = <HTMLInputElement>event.target;
    const electricityGroup = this.metersFormGroup.controls.electricity;
    electricityGroup.controls.hasSolarPanels.setValue(checked);
  }
}

_('errorMessages.requiredField');
_('errorMessages.solarPanelsRange');
_('pages.move.newMeters.smartMeterAndMeterType.MONO');
_('pages.move.newMeters.smartMeterAndMeterType.BI');
_('pages.move.newMeters.smartMeterAndMeterType.SMART_MONO');
_('pages.move.newMeters.smartMeterAndMeterType.SMART_BI');
