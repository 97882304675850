import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { InvoicingMethod, MoveDTO } from '@app/core/state/move.state';
import { Address } from '@app/shared/models/address.interface';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  MoveAddressFormGroup,
  NewAddressStepFromGroup,
} from '../../components/move-form/steps/new-address/new-address.interface';
import { BelgiumShortCountry } from '../../models/movein.interface';

@Injectable({
  providedIn: 'root',
})
export class MoveFormsService {
  constructor(private readonly translate: TranslateService) {}
  private readonly zipCodeRange = { min: 1000, max: 9999 };
  private readonly eanErrorKeys = {
    invalidEAN: 'errorMessages.move.invalidEan',
  };

  getAddressFormGroup = (): FormGroup<MoveAddressFormGroup> => {
    return new FormGroup({
      address: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      box: new FormControl('', []),
      zipCode: new FormControl('', [
        Validators.required,
        Validators.min(this.zipCodeRange.min),
        Validators.max(this.zipCodeRange.max),
      ]),
      locality: new FormControl('', [Validators.required]),
      country: new FormControl({ value: this.translate.instant('common.country.belgium'), disabled: true }, [
        Validators.required,
      ]),
    });
  };

  getErrorMessagesForEan(): { [key: string]: string } {
    return this.setErrorTranslation(this.eanErrorKeys);
  }

  setErrorTranslation(messages: { [key: string]: string }): { [key: string]: string } {
    return Object.keys(messages).reduce((accumulator, key) => {
      accumulator[key] = this.translate.instant(messages[key]);
      return accumulator;
    }, {});
  }

  getNewAddressStepFormGroup = (): FormGroup<NewAddressStepFromGroup> => {
    return new FormGroup({
      requestedServiceDate: new FormControl('', [Validators.required]),
      energyType: new FormGroup(
        {
          gas: new FormControl(null, [Validators.required]),
          electricity: new FormControl(null, [Validators.required]),
        },
        [this.atLeastOneEnergyTypeChecked()]
      ),
      newAddress: this.getAddressFormGroup(),
      invoicingData: new FormGroup({
        invoicingMethod: new FormControl(null, [Validators.required]),
      }),
    });
  };

  private atLeastOneEnergyTypeChecked(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const gas = group.controls.gas.value;
      const electricity = group.controls.electricity.value;
      return gas || electricity ? null : { atLeastOneChecked: true };
    };
  }

  updatedBillingInfoMoveOut(moveDTO: MoveDTO, address: Address, invoicingMethod: InvoicingMethod): MoveDTO {
    moveDTO.billingInfo.address = { ...moveDTO.billingInfo.address, ...address };
    //We should use electronicInvoicing and not invoiceSendingType.
    // InvoiceSendingType is set up on backned based on electronicInvoicing === true to EMAIL and electronicInvoicing === false to POST
    moveDTO.billingInfo.electronicInvoicing = invoicingMethod !== InvoicingMethod.POST;
    return moveDTO;
  }

  getFormAddress(billingAddress: FormGroup<MoveAddressFormGroup>): Address {
    const addressControls = billingAddress.controls;
    return {
      street: addressControls.address.value,
      streetNumber: addressControls.number.value,
      zip: addressControls.zipCode.value,
      box: addressControls.box.value,
      city: addressControls.locality.value,
      country: BelgiumShortCountry,
    };
  }
}

_('errorMessages.move.invalidEan');
